export const WORDS = [
  "JERKED",
  "RECTUM",
  "TINKLE",
  "SUFFER",
  "HAMMER",
  "SYSLOG",
  "FEDORA",
  "BOOMER",
  "FIDDLE",
  "ECZEMA",
  "GAGGLE",
  "NAPALM",
  "ERRORS",
  "FRESCA",
  "DOKKEN",
  "TWINKS",
  "ABUSED",
  "ARMPIT",
  "CHRIST",
  "BABOON",
  "STDERR",
  "ZARDOZ",
  "YAPPER",
  "ANYHOO",
  "EFFIGY",
  "FANBOY",
  "ENDIAN",
  "RUSSIA",
  "NOODLE",
  "URANUS",
  "BLIMPY",
  "WOBBLE",
  "FUCKED",
  "EXPOSE",
  "CRUSTY",
  "BLEACH",
  "INCHES",
  "GLUMLY",
  "MATURE",
  "QUEERS",
  "FAMINE",
  "LATKES",
  "BIDETS",
  "SPIFFY",
  "REPENT",
  "BIBLES",
  "DICKER",
  "DIAPER",
  "BUTKUS",
  "GAYEST",
  "QUEENS",
  "DINGUS",
  "GIGGLE",
  "CLONKY",
  "PIRATE",
  "FUNBOY",
  "WEASEL",
  "ALDRIN",
  "HEROIN",
  "BARFLY",
  "CANKER",
  "ASIANS",
  "CRUISY",
  "PRINTF",
  "NITWIT",
  "MINION",
  "CHUBBY",
  "NONFAT",
  "COSINE",
  "BRUISE",
  "DAMNIT",
  "DEUCED",
  "VIAGRA",
  "MORMON",
  "IDIOCY",
  "SMURFS",
  "BIGOTS",
  "BROTHA",
  "CLAMPS",
  "CHEEKS",
  "BALLSY",
  "SQUIRM",
  "BEHIND",
  "HORSEY",
  "PAJAMA",
  "HARLOT",
  "PACINO",
  "BOTTOM",
  "SAILOR",
  "SETENV",
  "MUPPET",
  "GHETTO",
  "BURGER",
  "COILED",
  "ZOMBIE",
  "TIGHTS",
  "FARTED",
  "KERNEL",
  "GOPHER",
  "POLICE",
  "BJORKY",
  "GUNMAN",
  "WUMPUS",
  "THROAT",
  "PLOWED",
  "ARRAYS",
  "WIENER",
  "BORING",
  "BANGED",
  "OAFISH",
  "BANANA",
  "POKING",
  "BURGLE",
  "BATONS",
  "MALLET",
  "MOBIUS",
  "NIPPLE",
  "CELERY",
  "DEVOPS",
  "AUTIST",
  "BAGELS",
  "KRAUTS",
  "PEEING",
  "CHICKS",
  "VERBED",
  "TOILET",
  "ADULTS",
  "GRINDR",
  "SUCKER",
  "RUMPUS",
  "LIQUOR",
  "PIGLET",
  "GOLEMS",
  "CACKLE",
  "RIMJOB",
  "MAYHAP",
  "LONELY",
  "ACHING",
  "HOBBIT",
  "FETISH",
  "BOWELS",
  "SEXIST",
  "CRYPTO",
  "CUDDLE",
  "BASHED",
  "CLIMAX",
  "SOILED",
  "DOUCHE",
  "TRANNY",
  "SHITTY",
  "MOUTHY",
  "STALLS",
  "CARROT",
  "MOTHRA",
  "GOOGLY",
  "SLITHY",
  "GAPING",
  "GRABBY",
  "BADONK",
  "GOBBLE",
  "FRITOS",
  "JOYPAD",
  "LOTION",
  "BOVINE",
  "FRILLY",
  "URCHIN",
  "DOODLE",
  "CREEPY",
  "MELONS",
  "GAUCHE",
  "BOUNCY",
  "COMMIE",
  "CANADA",
  "MURDER",
  "FATLAB",
  "CENSOR",
  "ADORBS",
  "EROTIC",
  "EIGHTH",
  "COFFEE",
  "SINNER",
  "GRAPES",
  "GERBIL",
  "BISHOP",
  "WAFFLE",
  "SMOOSH",
  "BATTER",
  "CRIKEY",
  "SMEARS",
  "CONDOM",
  "NAMBLA",
  "CHEESE",
  "FRYGAR",
  "PICARD",
  "STROKE",
  "PUCKER",
  "PRICKY",
  "KERMIT",
  "HOTTIE",
  "MUTANT",
  "MEXICO",
  "KNOBBY",
  "CRAMPY",
  "TEABAG",
  "DOOFUS",
  "CANCEL",
  "COOKIE",
  "SCULLY",
  "BOSNIA",
  "CHEETO",
  "SHAVED",
  "BEARDO",
  "BARFED",
  "SALAMI",
  "BABBLE",
  "WASTED",
  "BLARGH",
  "GROOVY",
  "ATTACK",
  "PHOBES",
  "REBOOT",
  "PISSED",
  "PAGEUP",
  "CHOKED",
  "FEMDOM",
  "PIMPLY",
  "ALBEDO",
  "CATBOX",
  "DEFEAT",
  "CHOMPY",
  "HAMMIE",
  "BOXERS",
  "AMIGOS",
  "BUGGER",
  "APPLET",
  "WEENIE",
  "BRUNCH",
  "BATMAN",
  "PREGGO",
  "BULLET",
  "PIDDLY",
  "HARDON",
  "GROPED",
  "BEAVER",
  "BUMBLE",
  "DAEMON",
  "LADIES",
  "PAROLE",
  "HERPES",
  "ASSMAR",
  "RUCKUS",
  "SPIGOT",
  "JERKER",
  "BREAST",
  "PACMAN",
  "GANGLY",
  "THINGY",
  "DOLLOP",
  "MAYDAY",
  "MEOWTH",
  "NUDIST",
  "GIRLIE",
  "KEGGER",
  "CRONCH",
  "HUNKER",
  "LESBOS",
  "YOOHOO",
  "FONDUE",
  "QUICHE",
  "BRUTAL",
  "BIGGIE",
  "CLOROX",
  "TELNET",
  "HACKED",
  "HURRAY",
  "ADDICT",
  "PECKER",
  "BOGGLE",
  "LEHRER",
  "SODOMY",
  "HITLER",
  "MUZZLE",
  "NOUGAT",
  "CASTRO",
  "COWBOY",
  "FLOPPY",
  "WHITEY",
  "VAGINA",
  "FEEBLE",
  "HOOKER",
  "MANASS",
  "SLURRY",
  "LOGOUT",
  "GOATSE",
  "BUGLES",
  "CRABBY",
  "TARDIS",
  "GENTOO",
  "STUPID",
  "DALEKS",
  "FONZIE",
  "GHOSTS",
  "BIKINI",
  "USENET",
  "INCEST",
  "WHINGE",
  "POPEYE",
  "SPURTY",
  "PEANUT",
  "CISGAY",
  "ABSORB",
  "INBRED",
  "SEXUAL",
  "INCELS",
  "PHREAK",
  "ANAKIN",
  "HATRED",
  "NUTWAD",
  "URINAL",
  "DANGLE",
  "BLASTS",
  "CANCER",
  "DWARFS",
  "MANURE",
  "CROTCH",
  "GALAGA",
  "ESCORT",
  "FLAPPY",
  "BUTTER",
  "DRUNKS",
  "GREASY",
  "FONDLE",
  "CHEKOV",
  "ASTHMA",
  "RETARD",
  "KRAKEN",
  "FANFIC",
  "POKERY",
  "ULTIMA",
  "MUFFIN",
  "UPLOAD",
  "BIONIC",
  "STONED",
  "STABBY",
  "NAGGER",
  "RAPPER",
  "RAPIST",
  "FUCKER",
  "SWISHY",
  "MOLEST",
  "AMBIEN",
  "SEDATE",
  "MULLAH",
  "FILTHY",
  "SQUAWK",
  "BONKED",
  "ATANDT",
  "TAMPON",
  "LEMONY",
  "CLUMPS",
  "JIZZED",
  "BONERS",
  "GOBLIN",
  "CREPES",
  "HURGLE",
]
